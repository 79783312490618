var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l(([
      {
        label: '累计进件金额（万元）',
        value: _vm.columnAmount(_vm.form.applyAmount / 10000),
        column0: {
          label: '进件笔数(笔)',
          value: _vm.columnAmount(_vm.form.applyCount, {
            fixed: 0,
          }),
        },
        column1: {
          label: '件均金额(万元)',
          value: _vm.columnAmount(_vm.form.applyAmount / _vm.form.applyCount / 10000),
        },
      },

      {
        label: '累计额度批复（万元）',
        value: _vm.columnAmount(_vm.form.approvaledAmount / 10000),
        column0: {
          label: '批复笔数(笔)',
          value: _vm.columnAmount(_vm.form.approvaledCount, {
            fixed: 0,
          }),
        },
        column1: {
          label: '件均金额(万元)',
          value: _vm.columnAmount(
            _vm.form.approvaledAmount / _vm.form.approvaledCount / 10000
          ),
        },
      },

      {
        label: '累计成交金额（万元）',
        value: _vm.columnAmount(_vm.form.loanedAmount / 10000),
        column0: {
          label: '成交笔数(笔)',
          value: _vm.columnAmount(_vm.form.loanedCount, {
            fixed: 0,
          }),
        },
        column1: {
          label: '件均金额(万元)',
          value: _vm.columnAmount(_vm.form.loanedAmount / _vm.form.loanedCount / 10000),
        },
      },

      //
    ]),function(item,index){return _c('div',{key:index,staticClass:"bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"},[_c('div',{staticClass:"flex_1 font_16 font_bold"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"font_24 font_bold m_t_8"},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"dis_flex m_t_8"},[_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column0.label))]),_c('div',{staticClass:"col_66BB6A font_bold m_l_8"},[_vm._v(_vm._s(item.column0.value))])]),_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column1.label))]),_c('div',{staticClass:"col_66BB6A font_bold m_l_8"},[_vm._v(_vm._s(item.column1.value))])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }